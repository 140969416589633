import React from 'react';
import YouTube from "react-youtube"
import cn from 'classnames';
import css from './CourseLessonExample.module.css'

import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const opts = {
  width: '100%',
  height: '100%',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};

export const CourseLessonExample = (props) => (
  props.lesson.enabled ? <Compo {...props} /> : null
)


export const Compo = ({lesson}) => (
  <div className={cn('container-full', css.wrapper)}>
    <div className="container-big">
      <div className="row">


        <div className="col-xs-12 col-md-5">
          <div className="text-box-title">{lesson.title}</div>

          <div>{lesson.desc}</div>
          <br/>
          {
            lesson.items.map((item, index)=> {
              return (
                <div className={css.item} key={index}>
                  <FontAwesomeIcon icon={faCheck} /> {item.text}
                </div>
              )
            })
          }
        </div>

        <div className="col-xs-12 col-md-7 first-md">
          <div className="videoWrapper">
            <YouTube containerClassName="youtube-video" videoId={lesson.video1} opts={opts} />
          </div>
        </div>
      </div>
    </div>
  </div>
)
