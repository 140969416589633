import '../../styles/flexboxgrid.css';
import '../../styles/carousel-responsive.css';

import React from "react"
import { graphql } from "gatsby"
import YouTube from "react-youtube"


import { CourseSummaryTable } from "../course/course-summary-table/CourseSummaryTable"
import { CarouselCarouselIntro } from "../course/course-carousel-intro/CourseCarouselIntro"
import { CourseDescriptionGoalsRequirements } from "../course/course-description/CourseDescriptionGoalsRequirements"
import { SeparatorWave } from "../course/separator-wave/SeparatorWave"
import { CourseProgram } from "../course/course-program/CourseProgram"
import { CourseMode } from "../course/course-mode/CourseMode"
import { CourseLessonExample } from "../course/course-lesson-example/CourseLessonExample"
import { CourseInstructorProfile } from "../course/course-instructor-profile/CourseInstructorProfile"
import { CarouselCarouselReview } from "../course/course-carousel-reviews/CourseCarouselReview"
import { CoursePriceSingle } from "../course/course-price/CoursePriceSingle"
import { CourseMailChimpNewsletter } from "../course/course-newsletter/CourseMailChimpNewsletter"
import { CourseDescription } from "../course/course-description/CourseDescription"
import { CourseHeaderMain } from "../course/couse-header-main/CourseHeaderMain"
import Layout from "../layout"
import SEO from "../seo"
import { Footer } from "../shared/Footer"

export const imgTrainingBig = graphql`
  fragment imgTrainingBig on File {
    childImageSharp {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`


export const thumb = graphql`
  fragment thumb on File {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const CoursePageFull = (props) => {
  const opts = {
    width: '100%',
    height: '100%',
    playerVars: { // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 0,
      showinfo: 0,
      modestbranding: 1
    }
  };

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    /*event.target.pauseVideo();*/
  }

  return <Layout>
    <SEO title={props.course.header.titleSEO} />
    <SeparatorWave bg1={props.course.theme.separatorHeaderColor1} bg2={props.course.theme.separatorHeaderColor2} />

    <div className="container-big">
      <CourseHeaderMain
        {...props}
        theme={props.course.theme}
        info={props.course.header}
        actionButtons={props.course.actionButtons.main}
      />
    </div>


    <div className="container-big">
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-7">
          <CourseDescription description={props.course.description}/>
        </div>

        <div className="col-xs-12 col-sm-6 col-md-5">
          <br/>
          <CourseSummaryTable data={props.course.summary} />
        </div>
      </div>
    </div>

    <div className="container-big">
      <br/>
      <CarouselCarouselIntro
        {...props}
        items={props.course.carousels.main}
      />
    </div>

    <div className="container-big">
      <div className="row">
        <div className="col-xs-12 col-md-8">
          <CourseDescriptionGoalsRequirements data={props.course.metadata} />
        </div>
      </div>
    </div>

    <div style={{padding: '20px 0'}}></div>

    <div className="container-big">
      <div className="videoWrapper">
        <YouTube containerClassName="youtube-video" videoId={props.course.videos.teaser1} opts={opts} onReady={onReady}/>
      </div>

      <div style={{paddingTop: 50, paddingBottom: 20}}>
        <CourseProgram program={props.course.program}  />
      </div>
    </div>

    <div className="container-full">
      <SeparatorWave bg1={props.course.theme.separatorColor1} bg2={props.course.theme.separatorColor2} />
    </div>

    <div className="container-big">

      <br/>
      <br/>
      <br/>
      <CourseMode {...props} mode={props.course.mode} />
      <br/>
      <br/>
      <br/>
    </div>

    <CourseLessonExample
      lesson={props.course.exampleLesson}
    />

    <div className="container-big">
      <CourseInstructorProfile {...props}/>
    </div>

    <div className="container-full">
      <SeparatorWave bg1={props.course.theme.separatorColor1} bg2={props.course.theme.separatorColor2} />
    </div>

    <div className="container-big">
      <CarouselCarouselReview
        {...props}
        items={props.course.carousels.reviews}
      />
    </div>

    <div style={{paddingTop: '3rem'}} id="pricingPanel">
      <CoursePriceSingle
        {...props}
        pricing={props.course.pricing}
        theme={props.course.theme}
      />
    </div>


    <div id="newsletter">
      <CourseMailChimpNewsletter
        newsletter={props.course.newsletter}
        theme={props.course.theme}
      />
    </div>

    <Footer/>


    {/*<Link to="/">Go back to the homepages</Link>*/}


  </Layout>
}
