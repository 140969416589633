import React from 'react';
import css from './CourseNewsletter.module.css';
import cn from 'classnames';
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import MailchimpSubscribe from "react-mailchimp-subscribe"

// const url = "//fabio_biondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&id=85573d8b21";
/**
 * NOTE: questo url si trova cliccando su SIGNUP FORMS e EMBEDDED FORMS
 * https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21
 * @type {string}
 */
// const url = "https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21";

export const CourseMailChimpNewsletter = (props) => (
  props.newsletter.enabled ? <Compo {...props}></Compo> : null
)


const Compo = ({ newsletter, theme }) => (
  <div className={cn('container-full', css.wrapper)}>
    <div className={cn('container-big', 'center', css.newsletter_box)} style={{background: 'white'}}>
      <div className="text-paragraph-title">
        ISCRIVITI ALLA NEWSLETTER
      </div>
      <br/>

      <div style={{padding: '0 30px'}}>
        {/*<div className={css.InputAddOn}>
          <input className="form-control text horizontal"
                 style={{ maxWidth: '80%'}}
                 placeholder="La tua email"/>
          <button className={cn('button', 'button-round-right')}>

            <FontAwesomeIcon icon={faCheck} />
            <span className="hide-on-mobile"> ISCRIVITI</span>
          </button>
        </div>
*/}
        {/*<MailchimpSubscribe url={url}/>*/}

            <MailchimpSubscribe
              url={newsletter.campaignFormUrl}
              render={({ subscribe, status, message }) => (
                <>

                  <SimpleForm theme={theme} onSubmitted={formData => subscribe(formData)} />
                  {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                  {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
                  {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
                </>
              )}
            />

        </div>

    </div>
  </div>
)


// a basic form
const SimpleForm = ({ theme, status, message, className, style, onSubmitted }) => {
  let input;
  const submit = () =>
    input &&
    input.value.indexOf("@") > -1 &&
    onSubmitted({
      EMAIL: input.value
    });

  return (
    <>
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className={css.InputAddOn}>

        <input
          ref={node => (input = node)}
          type="email"
          className="form-control text horizontal"
          style={{ maxWidth: '80%'}}
          placeholder="La tua email"
        />
        <button
          onClick={submit}
          className={cn('button', 'button-round-right')}
          style={{backgroundColor: theme.buttonColor}}
        >

          <FontAwesomeIcon icon={faCheck} />
          <span className="hide-on-mobile"> ISCRIVITI</span>
        </button>
      </div>
      <br/>
    </>
  );
};

export default SimpleForm;
