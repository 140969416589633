import React from 'react';
import css from './CourseProgram.module.css';

export const CourseProgram = ({ program = {}}) => (
  <div>
    <div className="text-box-title center">
      PROGRAMMA
    </div>
    <div className="row">
      <div className="col-xs-12 col-sm-6">
        <List data={program.colLeft}/>
      </div>

      <div className="col-xs-12 col-sm-6">
        <List data={program.colRight}/>
      </div>
    </div>
  </div>
)


const List = (props) => {
  return (
    props.data.map((item, index) => {
      return item.type === 'title' ?
        <div className={'row ' + css.item} key={index} style={{marginTop: 20}}>
          <strong className={css.title}>{item.value}</strong>
        </div> :
        <div className={'row ' + css.item} key={index} style={{paddingLeft: 10}}>
          {item.value}
        </div>

    })
  )

}
