import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"

export default class CorsoAngularNgrx extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            angular_core_concepts: file(relativePath: { eq: "courses/angular-core-concepts/angular-core-concepts-ts.png" }) { ...thumb },

            carouselIntro1: file(relativePath: { eq: "courses/angular-core-concepts/carouselDiagram.png" }) { ...imgTrainingBig },

            review_daniela_ferrante: file(relativePath: { eq: "courses/angular-fundamentals/reviews/review_daniela_ferrante.png" }) { ...imgTrainingBig },
            review_zack_nero: file(relativePath: { eq: "courses/angular-fundamentals/reviews/review_zack_nero.png" }) { ...imgTrainingBig },
            review_marco_brunet: file(relativePath: { eq: "courses/angular-fundamentals/reviews/review_marco_brunet.png" }) { ...imgTrainingBig },

            mode1: file(relativePath: { eq: "courses/_general/mode/mode-thumb-1.png" }) { ...thumb },
            mode2: file(relativePath: { eq: "courses/_general/mode/mode-thumb-2.png" }) { ...thumb },
            mode3: file(relativePath: { eq: "courses/_general/mode/mode-thumb-3.png" }) { ...thumb },
            profile: file(relativePath: { eq: "courses/_general/profile/profile-round-gde-1.png" }) { ...thumb },
            pricing_bg: file(relativePath: { eq: "courses/angular-ngrx/pricing/bg_price_1.png" }) { ...thumb },

          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#dd0031',
    separatorHeaderColor2: '#c3002f',
    separatorColor1: '#dd0031',
    separatorColor2: '#4a90e2',
    buttonColor: '#dd0031'
  },
  header: {
    titleSEO: 'Corso Angular, Javascript ES6 & Typescript',
    title: '<strong>ANGULAR</strong><span style="color: #dd0031">8</span> & <strong>TYPESCRIPT</strong><br /> CORE CONCEPTS',
    shortTitle: '<strong>ANGULAR</strong><span style="color: #dd0031">8</span> & <strong>TYPESCRIPT</strong> <br />CORE CONCEPTS',
    subtitle: 'Un corso sulle principali funzionalità di Javascript ES6, Typescript e un’introduzione sull’ultima release di Angular per acquisire una solida base sul framework',
    image: 'angular_core_concepts',
    difficulty: 'principiante',
    duration: '16-24 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'Una panoramica sulle principali funzionalità di <strong>Javascript ES6 e Typescript</strong> che ogni sviluppatore web dovrebbe conoscere e un’introduzione sull’<strong>ultima release di Angular</strong> per acquisire una solida base sul framework: styling, directives, template driven forms, servizi e dependency injection, server-side communication, router e una veloce introduzione alla creazione di componenti e all’organizzazione di progetti'},
    { title: 'DURATA', text: 'Il corso ha una durata variabile tra le 16 e le 24 ore, quindi 2 o 3 giornate: <br /><br />• 16 ore: il corso sarà intensivo, con un ritmo molto veloce e ci concentreremo sui contenuti <br/>• 24 ore: ritmo più lento, argomenti affrontati in modo più approfondito, gli studenti potrano eseguire alcune esercitazioni e configurare l\'ambiente di lavoro'},
  ],
  metadata: [
    { title: 'OBIETTIVO DEL CORSO', text: 'acquisire una solida base sul framework: creare applicazioni multi-view con angular router, comunicare con il server attraverso REST API, creare form e gestire la validazione, utilizzare direttive e  servizi forniti dal framework Angular, sfruttare le potenzialità introdotte da ES6 e Typescript e creare i primi componenti custom'},
    { title: 'REQUISITI', text: 'familiarità con almeno un linguaggio di programmazione e possibilmente conoscere i concetti fondamentali su cui si basa il paradigma della programmazione ad oggetti. Aver già utilizzato HTML, CSS e Javascript sicuramente ti saranno di grande aiuto' },
  ],
  carousels: {
    main: [
      {img: 'carouselIntro1'},
      /*{img: 'carouselIntro1', desc: 'Un abuso della dependency injection porta spesso ad uno stato unpredictable'},*/

    ],
    reviews: [
      {img: 'review_daniela_ferrante', desc: ''},
      {img: 'review_zack_nero', desc: ''},
      {img: 'review_marco_brunet', desc: ''},
    ],
  },
  videos: {
    teaser1: 'ovqUhtgJC-M',
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '16 / 24 ore' },
    { icon: faChartBar, label: 'Livello:', value: 'Principiante' },
    { icon: faCode, label: 'Versione Angular:', value: '8.x' },
    { icon: faCode, label: 'Versione Typescript:', value: '3.x' },
    { icon: faUser, label: 'Tipologia:', value: 'Corso in aula' },
  ],
  exampleLesson: {
    enabled: false,
    title: 'ESEMPIO LEZIONE',
    desc: 'In questa lezione:',
    video1: 'wEDOZvI_Tdg',
    items: [
      { text: 'Punto 1'},
      { text: 'Punto 2'},
      { text: 'Punto 3'},
      { text: 'Punto 4'},
      { text: 'Punto 5'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode1',
      title: 'SINGOLI ESEMPI',
      text: 'Decine di esempi pratici e casi d’uso sulle principali funzionalità del framework, le API più popolari e le best practices più utili per la creazione di codice riutilizzabile e scalabile.',
    },
    colCenter: {
      img: 'mode2',
      title: 'REAL APPS',
      text: 'Video lezioni dedicate alla teoria in cui saranno descritti i concetti tramite l’utilizzo di slide, diagrammi animati e materiale esclusivo.\n',
    },
    colRight: {
      img: 'mode3',
      title: 'TEORIA',
      text: 'Sessioni di live coding in cui saranno sviluppate Single Page Application che comunicano via API RESTful o RealTime Application che utilizzano Google Firebase per la sincronizzazione tra diversi client.\n'
    }
  },
  program: {
    colLeft: [
      { type: 'title', value: 'JAVASCRIPT ES2015 (aka ES6)'},
      { value: 'Javascript ES2015'},
      { value: 'Compiler, Transpiler e tool: Babel, Typescript e Webpack'},
      { value: 'var vs let vs const: function scope vs block scope'},
      { value: 'Template Literals'},
      { value: 'Arrow syntax'},
      { value: 'Destructuring'},
      { value: 'Object.assign() e Object concise syntax'},
      { value: 'Rest e Spread operator'},
      { value: 'Immutabilità'},
      { value: 'Array methods: find, map, filter, reduce, …'},
      { value: 'ES6 modules'},
      { value: 'Promises e operazioni asincrone'},
      { value: 'Classi e ereditarietà in ES6'},
      { type: 'title', value: 'TYPESCRIPT'},

      { value: 'Typescript vs ES6' },
      { value: 'Introduzione ai decoratori' },
      { value: 'Custom Types' },
      { value: 'Interfacce' },
      { value: 'Typescript in Angular' },
      { type: 'title', value: 'DEPENDENCY INJECTION'},
      { value: 'Creazione custom Service Providers' },
      { value: '@Injectable decorator: quando e perché usarlo?' },
      { value: 'Utilizzare i servizi per condividere dati tra routes e componenti' },
      { value: 'Utilizzare i servizi per gestire lo stato dei componenti' },
      { type: 'title', value: 'SERVER SIDE COMMUNICATION'},
      { value: 'Installazione e configurazione di un REST mock server per i test' },
      { value: 'Utilizzo del servizio HttpClient per la comunicazione con il server' },
      { value: 'Comunicazione con REST API' },
      { value: 'Creazione di applicazioni CRUD: Creare, Read, Update, Delete' },
      { value: 'Immutable vs Mutable data' },

    ],
    colRight: [
      { type: 'title', value: 'Environment & Tools' },
      { value: 'NodeJS, NPM and multiple Node environments with NVM' },
      { value: 'Install a Terminal on Mac and Windows' },
      { value: 'Install and use Angular CLI' },
      { type: 'title', value: 'ANGULAR FUNDAMENTALS' },
      { value: 'Creazione progetti con angular-cli' },
      { value: 'Integrazione framework e librerie CSS: Bootstrap, FontAwesome, …' },
      { value: 'Template Tags' },
      { value: 'Manipolazione DOM' },
      { value: '1-way e 2-way Binding' },
      { value: 'Decorator @Component e metadata' },
      { value: 'Dynamic HTML templates' },
      { value: 'Direttive built-in: *ngIf, *ngSwitch, *ngFor' },
      { value: 'Gestire e manipolare collezioni dati' },
      { type: 'title', value: 'DYNAMIC STYLING in ANGULAR' },
      { value: 'From static to dynamic CSS' },
      { value: 'inline CSS' },
      { value: 'css “unit” suffix' },
      { value: 'ngClass' },
      { value: 'ngStyle' },
      { type: 'title', value: 'ANGULAR FORMS' },
      { value: 'Keyboard Events' },
      { value: 'Template driven forms' },
      { value: 'Form & Input Template Reference Variables' },
      { value: 'ngModel directive: 1-way vs 2-ways binding' },
      { value: 'ngForm e submit' },
      { value: 'Form validators' },
      { value: 'Form Errors: custom messages in according with the error type' },
      { value: 'form state: dirty, invalid, touch, …' },
      { value: 'Validation with regular expressions' },
      { value: 'Custom validators with Directives' },
      { type: 'title', value: 'MULTIVIEW APPLICATIONS' },
      { value: 'Il modulo @angular/router' },
      { value: 'Single Page Application Multiview' },
      { value: 'Router Navigation' },
      { value: 'Feature Route components' },
      { value: 'Condividere dati tra le routes' },

    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Video Corso ANGULAR FUNDAMENTALS', extra: '10 ore'},
      { label: 'Mini video corso JAVASCRIPT ES6', extra: '2 ore'},
      { label: 'CheatSheet Javascript ES6', extra: 'PDF formato A4'},
      { label: 'CheatSheet Angular Fundamentals', extra: 'PDF formato A4'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/richiedi-preventivo-corso?subject=1',
      buyText: 'RICHIEDI QUOTAZIONE',
      target: '_self',
      scrollToID: ''
    },
    bg: 'pricing_bg',
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  }
}
