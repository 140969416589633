import React from 'react';

export const CourseMode = (props) => (
  <div>
    <div className="text-box-title center">
      IL CORSO INCLUDE
    </div>
    <div className="row">
      <div className="col-xs-12 col-sm-4 " style={{ paddingRight: 10, paddingTop: 20}}>
        <Item {...props} item={props.mode.colLeft} />
      </div>
      <div className="col-xs-12 col-sm-4 " style={{ paddingRight: 10, paddingTop: 20}}>
        <Item {...props} item={props.mode.colCenter} />
      </div>
      <div className="col-xs-12 col-sm-4 " style={{ paddingTop: 20}}>
        <Item {...props} item={props.mode.colRight} />
      </div>
    </div>
  </div>
)


const Item = props => (
  <div>
    <div className="text-paragraph-title">{props.item.title}</div>
    <img
      width="100%"
      src={props.data[props.item.img].childImageSharp.fluid.src} alt=""/>
    <div >{props.item.text}</div>
  </div>
)
