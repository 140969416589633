import { Carousel } from "react-responsive-carousel"
import React from "react"

export const CarouselCarouselReview = (props) => (
  <div style={{maxWidth: 500, margin: '0 auto'}} >
    <div className="text-box-title center">
        <span className="light">RECENSIONI</span><span className="bold">UTENTI</span>
    </div>
    <br/>
    <Carousel  showArrows={true} infiniteLoop emulateTouch dynamicHeight showThumbs={false}>
      {
        props.items.map(item => {
          return (
            <div key={item}>
              <img src={props.data[item.img].childImageSharp.fluid.src} alt=""/>
            </div>
          )
        })
      }
    </Carousel>
  </div>
)
