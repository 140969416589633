import React from "react"

export const CourseDescriptionGoalsRequirements = props => (
  <div>
      {
        props.data.map((item, index) => {
              return (
                <div key={index}>
                {item.title ? <div className="text-paragraph-title" dangerouslySetInnerHTML={{ __html: item.title }} /> : <noscript />}

                <div dangerouslySetInnerHTML={{ __html: item.text}} />

                <br/>
                </div>
              )
        })
        }
  </div>

  /*<div>
      <div className="text-paragraph-title">OBIETTIVO DEL CORSO</div>
      Creare applicazioni Angular scalabili, testabili e semplici da mantenere utilizzando NGRX per la gestione dello stato applicativo, applicando una netta separazione tra architettura dati e presentational layer.

      <br/>
      <br/>
      <div className="text-paragraph-title">REQUISITI</div>
      Per comprendere gli argomenti trattati nel corso è necessario avere una buona conoscenza delle seguenti funzionalità del framework Angular: componenti custom, moduli, dependency injection, router e lazy loading.
  </div>*/
)
