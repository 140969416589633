import React from 'react';
import css from './CoursePriceSingle.module.css';
import cn from 'classnames';
import { goto } from "../../utils/links"

export const CoursePriceSingle = ({theme, pricing, data}) => {
  const styles = {
    wrapper: {
      backgroundImage: `url(${data.pricing_bg.childImageSharp.fluid.src})`
    }
  }

  return (
    <div className={cn('container-full', css.wrapper)} style={styles.wrapper}>
      <div className={css.box}>
       {/* <img src={data.pricing_bg.childImageSharp.fluid.src} alt=""/>*/}

        <div className={cn('center', css.title)}>COSA INCLUDE IL CORSO</div>
        {
          pricing.items.map((item, index)=> {
            return (
              <div key={index}>
                <div className={cn('row', css.item)}>
                  <div className={cn('col-xs-12 col-md-8', css.item_title)}>
                    {item.label}
                  </div>
                  <div className="col-xs-12 col-md-4">
                    {item.extra}
                  </div>
                </div>
              </div>
            )
          })
        }
        <br/>

        {
          pricing.priceOffer ?
            <div className="center">
              <div className={cn(css.price)}>
                <span style={{textDecoration: 'line-through'}}>€ {pricing.price}</span>
                <span style={{color: 'red'}}> € {pricing.priceOffer}</span>
                <br/>
              </div>
              <small>{pricing.priceOfferDescription}</small>
            </div>
            :
            pricing.price ? <div className={cn('center', css.price)}>
              € {pricing.price}
            </div> : null
}
        <br/>

        {
          pricing.button.visible ?
            <div className="center">
              <button
                disabled={!pricing.button.enabled}
                className="button button1"
                onClick={() => goto(pricing.button.buyLink, pricing.button.target, pricing.button.scrollToID)}
                style={{backgroundColor: theme.buttonColor}}
              >
                {pricing.button.buyText}
              </button>
            </div>
            : null
        }
      </div>
    </div>
  )
}
