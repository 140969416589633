import { Carousel } from "react-responsive-carousel"
import React from "react"

export const CarouselCarouselIntro = (props) => (
  <Carousel
    showArrows={false}
    infiniteLoop={props.items.length > 1}
    emulateTouch
    autoPlay
    stopOnHover
    interval={3000}
    showStatus={false}
    showThumbs={props.items.length > 1}
    showIndicators={props.items.length > 1}
  >
    {
      props.items.map(item => {
        return (
          <div key={item}>
            <img src={props.data[item.img].childImageSharp.fluid.src} alt=""/>

            {item.desc ? <p className="legend">
              {item.desc}
            </p> : null}
          </div>
        )
      })
    }
  </Carousel>
)
