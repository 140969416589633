import React from "react"

export const CourseDescription = props => (
  <div>
      {
          props.description.map((item, index) => {
              return (
                <div  key={index}>
                    {item.title ? <div className="text-paragraph-title" dangerouslySetInnerHTML={{ __html: item.title }} /> : <noscript />}

                    <div dangerouslySetInnerHTML={{ __html: item.text}} />

                    <br/>
                </div>
              )
          })
      }
  </div>
)
