import React from "react"
import { goto } from "../../utils/links"

export const CourseHeaderMain = ({theme, info, data, actionButtons}) => {



  return (
    <div>

      <div className="row middle-xs">
        <div className="col-xs-12 col-sm-8">
          <div className="text-header-main-title">
            <div dangerouslySetInnerHTML={{ __html: info.title}} />
          </div>
          <div className="text-header-main-subtitle">
            <div dangerouslySetInnerHTML={{ __html: info.subtitle}} />
          </div>

          <br/>
          <br/>
        </div>

        <div className="col-xs-12 col-sm-4" style={{padding: 0}}>
          <div
            className="right"
          >
            <img
              width="100%"
              src={data[info.image].childImageSharp.fluid.src} alt=""/>
          </div>
        </div>

        <br/>
      </div>

      <br/>
      <div className="center">
        {
          actionButtons.visible ?
            <button
              style={{backgroundColor: theme.buttonColor}}
              className="button button1"
              disabled={!actionButtons.enabled}
              onClick={() => goto(actionButtons.buyLink, actionButtons.target, actionButtons.scrollToID)}
            >
              {actionButtons.buyText}
            </button> : null
        }

      </div>
      <br/>

    </div>
  )
}










