import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import css from './CourseSummaryTable.module.css';

export const CourseSummaryTable = (props) => (
  <>
    {
      props.data.map((item, i)=> {
        return (
          <div className={'row ' + css.item} key={i}>
            <div className="col-xs-4" style={{fontSize: '1.5rem'}}>
              <FontAwesomeIcon icon={item.icon} />
            </div>

            <div className="col-xs-8 right" style={{fontSize: '1.3rem'}}>
              {item.label} <strong>{item.value}</strong>
            </div>
          </div>
        )
      })
    }
  </>
)
