import React from 'react';
import css from './CourseInstructorProfile.module.css';
import { graphql, StaticQuery } from "gatsby"


export class CourseInstructorProfile extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            profile: file(relativePath: { eq: "courses/_general/profile/profile-round-gde-1.png" }) { ...thumb },
            angular_badge: file(relativePath: { eq: "courses/_general/profile/2019-GDE-Angular-Badge.png" }) { ...thumb },
            web_badge: file(relativePath: { eq: "courses/_general/profile/2019-GDE-Web-Technologies-Badge.png" }) { ...thumb },
          }
        `}
        render={data => <Component data={data} /> }
      />
    )
  }
}

export const Component = props => (
  <div className={css.wrapper}>
    <div className="text-box-title center">
      <span className="light">ISTRUTTORE: </span>
      <br className="show-on-xs"/>
      <span className="bold">FABIO BIONDI</span>
    </div>

    <br/>

    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-5">
        <img
          width="100%"
          src={props.data.profile.childImageSharp.fluid.src} alt=""/>
      </div>

      <div className="col-xs-12 col-md-7">

        <br/>

        <div>
          Fabio is both a community leader and a frequent contributor in many Italian front-end usergroups (Angular, React, Javascript, ...). He's a <strong>Google Developer Expert</strong> in Web Technologies and Angular with more than 15 years of experience in developing enterprise level applications, interactive experiences and UI components in several languages and web technologies.
          <br/><br/>
          Currently, he holds meetups, events and training courses all over Italy and he's speaker in a lot of conferences such as CodeMotion, WebAppConf, Angular Day and more...
          <br/><br/>
          His particular specialism is front-end applications and related aspects, both visuals and architectural. He daily uses cutting-edge libraries and frameworks offering training, development, code review and consulting on Angular, React, Typescript/ES6, Redux, NGRX, RxJS, Firebase, D3.js, CreateJS and several other web technologies.

          <br/>
          <br/>

          <div className="row">
            <div className="col-xs-6 col-sm-3 col-md-3">
              <img
                width={'100%'}
                style={{ marginRight: 20}}
                src={props.data.angular_badge.childImageSharp.fluid.src} alt=""/>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3">
              <img
                width={'100%'}
                src={props.data.web_badge.childImageSharp.fluid.src} alt=""/>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
)
